<template>
  <div class="reminder">
    <div
      class="row d-flex align-items-center justify-content-between reminder-bar"
    >
      <div class="d-flex align-items-center col-md-3 col-12">
        <div class="w-full mt-4" style="width: 100%">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="col mt-4">
          <b-form-group>
            <b-dropdown
              id="dropdown-action-table"
              variant="outline-light"
              text="Tarihe Göre Sırala"
              class="mr-2"
            >
              <b-dropdown-item>Tarihe Göre Sırala</b-dropdown-item>
              <b-dropdown-item>Seçilenleri Aktife Çek</b-dropdown-item>
              <b-dropdown-item>Seçilenleri Kaldır</b-dropdown-item>
              <b-dropdown-item>Seçilenleri Dışa Aktar</b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="row mt-8">
      <div
        style="cursor: pointer"
        class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-4"
        v-for="reminder in reminders"
        :key="reminder.id"
      >
        <div
          class="card dveb-card-white reminder-card position-relative"
          @click="setModalValue(reminder)"
        >
          <span :class="reminder.status" class="color-bar"></span>
          <div class="card-body">
            <div
              class="d-flex justify-content-between align-items-center card-header-title"
            >
              <h2>{{ reminder.title }}</h2>
            </div>
            <div class="reminder-info">
              <span>Karadeniz Apt.</span>
              <span class="mx-1">|</span>
              <span> Sultanbeyli/İstanbul</span>
            </div>
            <p class="card-content">{{ reminder.description }}</p>
            <div
              class="d-flex justify-content-between align-items-end card-image-footer"
            >
              <div class="d-flex">
                <strong>{{ reminder.startdate }}</strong>
                <strong v-if="reminder.enddate" class="mx-4">-</strong>
                <strong v-if="reminder.enddate">{{ reminder.enddate }}</strong>
              </div>
              <div class="image-area">
                <img
                  v-for="images in reminder.images"
                  :src="`/image/${images}`"
                  alt=""
                />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <div class="d-flex align-items-center">
                  <i class="fa fa-clock mr-3 text-danger"></i>
                  <p>Süre : <strong> Bir hafta içinde</strong></p>
                </div>
                <div class="ml-5 d-flex align-items-center">
                  <i class="fa fa-percent fa-sm mr-1 text-danger"></i>
                  <p>Teklifler : <strong>14</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="openModal"
      :title="modalValue.title"
      style="cursor: pointer"
    >
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="form-group">
            <label style="font-size: 15px" class="mb-5 d-flex" id="teklifMesaj"
              >Teklif Mesajı</label
            >
            <span style="font-size: 11.5px; color: gray">
              📌Tekliflerde vereceğin detaylı bilgi seçilme şansını arttırır. 🤓
              Ne kadar çok bilgi o kadar çok sipariş!
            </span>
            <br /><br />

            <textarea
              class="form-control"
              name="teklifMesaj"
              id="teklifMesaj"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label>İstenilen Ücret *</label>
            <input
              type="text"
              class="form-control form-control-lg"
              name="package"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label>Tahmini Süre *</label>
            <input
              type="text"
              class="form-control form-control-lg"
              name="package"
              placeholder="(Gün)"
            />
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button class="btn btn-danger" @click="openModal = false">
          Vazgeç
        </button>
        <button class="btn btn-success" @click="sendForm">Gönder</button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  name: "reminders-list",
  data() {
    return {
      search: "",
      reminders: [],
      totalCount: 0,
      openModal: false,
      modalValue: {},
    };
  },
  created() {
    this.setList();
  },

  methods: {
    setList() {
      this.reminders = [
        {
          id: 1,
          status: "onemli",
          title: "Su Borusu Tamiratı",
          description:
            "lorem ipsum dolor sit amet. lorem ipsum dolor sit amet. lorem ipsum dolor sit amet.",
          startdate: "01.07.2023",
          enddate: false,
          // images: ['image-150.png', 'image-150.png'],
        },
        {
          id: 2,
          status: "normal",
          title: "Elektrik Bağlantısı",
          description:
            "lorem ipsum dolor sit amet. lorem ipsum dolor sit amet. lorem ipsum dolor sit amet.",
          startdate: "01.07.2023",
          enddate: "04.07.2023",
          // images: ['image-150.png', 'image-150.png', 'image-150.png'],
        },
      ];
      this.totalCount = this.reminders.length;
    },
    searchInList() {
      this.setList();
      const searchValue = this.search;
      const foundItems = this.reminders.filter((item) => {
        for (const prop in item) {
          if (item[prop].toString().toLowerCase().includes(searchValue)) {
            return true;
          }
        }
        return false;
      });
      this.reminders = foundItems;
      this.totalCount = foundItems.length;
    },
    setModalValue(item) {
      this.modalValue = item;
      this.openModal = true;
    },
    sendForm() {
      this.openModal= false,
      Swal.fire({
        icon: 'success',
        title: "Teklifin Gönderildi! Takip etmek için tekliflerim kısmından kontrol edebilirsin.",
        width: 600,
        padding: "3em",
        color: "#716add",
        confirmButtonText:"Tamam",
        background: "#fff url(/images/trees.png)",
        backdrop: `
    rgba(0,0,123,0.4)
    url("https://sweetalert2.github.io/images/nyan-cat.gif")
    left top
    no-repeat
  `,
      });
    },
  },
  watch: {
    search: function () {
      this.searchInList();
    },
  },
};
</script>
<style lang="scss" scope>
.reminder {
  fieldset {
    margin-bottom: 0 !important;

    input {
      border: none !important;
      padding: 1.5rem !important;
      border-radius: 0.76923rem;

      &:focus {
        border: 1px solid $dveb-info !important;
        background-color: #fff !important;
      }
    }
  }

  .btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text),
  .btn.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn.btn-outline-light.dropdown-toggle,
  .show .btn.btn-outline-light.btn-dropdown {
    background-color: #fff;
  }

  .btn {
    border-radius: 10px !important;
    padding: 0.77rem 2rem;
  }

  .reminder-card {
    height: 100%;
    .color-bar {
      height: 1.46154rem;
      border-radius: 0.73077rem 0.73077rem 0rem 0rem;
      &.onemli {
        background-color: rgba(255, 91, 91, 0.5);
      }
      &.normal {
        background-color: rgba(255, 186, 64, 0.5);
      }
      &.az-onemli {
        background-color: rgba(64, 51, 255, 0.5);
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;

      padding: 0.62rem !important;

      .card-header-title {
        h2 {
          font-size: 1.07692rem;
          line-height: 1.69231rem;
          font-weight: 600;
        }

        i {
          font-size: 1.84615rem !important;
          color: $dveb-text-color;
        }
      }
      strong {
        color: $dveb-text-color;
        font-weight: 600;
      }
      .card-content {
        margin-top: 1.08rem;
        margin-bottom: 1.69rem;
        color: $dveb-text-color;
      }

      .card-image-footer {
        margin-top: auto;
        span {
          font-size: 0.92308rem;
          color: $dveb-text-color;
          line-height: 1.53846rem;
        }

        .image-area {
          display: flex;
          justify-content: flex-end;
          img {
            width: 3.07692rem;
            height: 3.07692rem;
            border-radius: 50%;
            border: 2px solid #3f98ff;
            margin-left: -1.5rem;
          }
        }
      }
    }
    .reminder-info {
      span {
        color: rgba($color: $dveb-text-color, $alpha: 0.5);
      }
      strong {
        color: $dveb-text-color;
      }
    }
  }
}
</style>
